<template>
  <div v-if="dialogFormVisible2">
    <el-dialog
      :title="titleTable"
      :visible.sync="dialogFormVisible"
      :width="'600px'"
      @close="close"
      :close-on-click-modal="false"
    >
      <el-form
        :model="deviceInfoForm"
        :rules="rules"
        ref="form"
        :label-width="'110px'"
        label-position="right"
        :class="{ 'my-form-readonly': isModelInfo }"
        size="large"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="gasId" :label="$t('add.排放物') + '：'">
              <el-select
                filterable
                v-model="deviceInfoForm.gasId"
                :placeholder="$t('global.请选择')"
              >
                <el-option
                  v-for="item in typeList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              prop="factor"
              :label="$t('add.计算因子') + '：'"
            >
              <el-input
                v-model="deviceInfoForm.factor"
                :placeholder="$t('global.请输入')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              prop="dataSource"
              :label="$t('add.数据来源') + '：'"
            >
              <el-input
                v-model="deviceInfoForm.dataSource"
                :placeholder="$t('global.请输入')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              prop="factorUnit"
              :label="$t('add.因子单位') + '：'"
            >
              <el-input
                v-model="deviceInfoForm.factorUnit"
                :placeholder="$t('global.请输入')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              prop="consumeMaterial"
              :label="$t('add.消耗物') + '：'"
            >
              <el-input
                v-model="deviceInfoForm.consumeMaterial"
                :placeholder="$t('global.请输入')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              prop="factorLevel"
              :label="$t('add.因子等级') + '：'"
            >
              <el-input
                v-model="deviceInfoForm.factorLevel"
                :placeholder="$t('global.请输入')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              prop="consumeMaterialUnit"
              :label="$t('add.消耗物单位') + '：'"
            >
              <el-input
                v-model="deviceInfoForm.consumeMaterialUnit"
                :placeholder="$t('global.请输入')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              prop="factorDescription"
              :label="$t('add.因子说明') + '：'"
            >
              <el-input
                v-model="deviceInfoForm.factorDescription"
                :placeholder="$t('global.请输入')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="20">
            <el-form-item prop="status" :label="$t('add.状态') + '：'">
              <el-radio v-model="deviceInfoForm.status" :label="0">
                {{ $t('global.启用') }}
              </el-radio>
              <el-radio v-model="deviceInfoForm.status" :label="1">
                {{ $t('global.停用') }}
              </el-radio>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <div v-if="isModelInfo">
          <el-button type="primary" :loading="posting" size="small" @click="close()">
            {{ $t("global.confirm") }}
          </el-button>
        </div>
        <div v-else>
          <el-button @click="close" size="small">{{ $t("global.cancel") }}</el-button>
          <el-button
            type="primary"
            :loading="posting"
            size="small"
            @click="submitForm('form')"
          >
            {{ $t("global.confirm") }}
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api'
import base from '@/templates/add'
export default {
  directives: {},
  filters: {},
  loading: false,
  extends: base,
  props: {},
  data () {
    return {
      titleTable: '',
      dialogFormVisible2: false,
      dialogFormVisible: false,
      posting: false,
      isModelEdit: false,
      isModelAdd: false,
      isModelInfo: false,
      deviceInfoForm: {
        gasId: '',
        factor: '',
        dataSource: '',
        factorUnit: '',
        consumeMaterial: '',
        factorLevel: '',
        consumeMaterialUnit: '',
        factorDescription: '',
        status: ''
      },
      rules: {
        gasId: [
          { required: true, message: this.$t('add.排放物Msg'), trigger: ['blur', 'change'] }
        ],
        factor: [
          { required: true, message: this.$t('add.计算因子Msg'), trigger: ['blur', 'change'] },
          { pattern: /\S+/, message: this.$t('global.emptyMsg') }
        ],
        dataSource: [
          { required: true, message: this.$t('add.数据来源Msg'), trigger: ['blur', 'change'] },
          { pattern: /\S+/, message: this.$t('global.emptyMsg') }
        ],
        factorUnit: [
          { required: true, message: this.$t('add.因子单位Msg'), trigger: ['blur', 'change'] },
          { pattern: /\S+/, message: this.$t('global.emptyMsg') }
        ],
        consumeMaterial: [
          { required: true, message: this.$t('add.消耗物Msg'), trigger: ['blur', 'change'] },
          { pattern: /\S+/, message: this.$t('global.emptyMsg') }
        ],
        factorLevel: [
          { required: true, message: this.$t('add.因子等级Msg'), trigger: ['blur', 'change'] },
          { pattern: /\S+/, message: this.$t('global.emptyMsg') }
        ],
        consumeMaterialUnit: [
          { required: true, message: this.$t('add.消耗物单位Msg'), trigger: ['blur', 'change'] },
          { pattern: /\S+/, message: this.$t('global.emptyMsg') }
        ],
        factorDescription: [
          { required: true, message: this.$t('add.因子说明Msg'), trigger: ['blur', 'change'] },
          { pattern: /\S+/, message: this.$t('global.emptyMsg') }
        ],
        status: [
          { required: true, message: this.$t('add.状态Msg'), trigger: ['blur', 'change'] }
        ]
      },
      typeList: []
    }
  },
  created () {
    api.auth.queryOptionTypeList({ type: 'warmGasType' }).then(res => {
      this.typeList = res.data.map(item => ({ ...item, label: item.name, value: item.id }))
    })
  },
  methods: {
    show () {
      this.dialogFormVisible2 = true
      this.$nextTick((_) => {
        this.dialogFormVisible = true
      })
    },
    close () {
      this.isModelEdit = false
      this.isModelAdd = false
      this.isModelInfo = false
      this.dialogFormVisible = false
      this.resetForm()
      this.$nextTick((_) => {
        this.dialogFormVisible2 = false
      })
    },
    add () {
      this.titleTable = this.$t('add.新增因子')
      this.isModelAdd = true
      this.deviceInfoForm.factorType = this.$parent.formInline.factorType
      this.show()
    },
    edit (data) {
      this.titleTable = this.$t('add.编辑因子')
      this.isModelEdit = true
      this.deviceInfoForm = { ...data }
      this.show()
    },
    view (data) {
      this.titleTable = this.$t('add.因子详情')
      this.isModelInfo = true
      this.deviceInfoForm = { ...data }
      this.show()
    },
    // 重制
    resetForm () {
      this.deviceInfoForm = {}
      this.$refs.form.resetFields()
    },
    // 提交
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.posting = true
          const query = {
            ...this.deviceInfoForm,
            id: this.deviceInfoForm.id,
            updateTime: null
          }
          try {
            await api.factor[this.isModelAdd ? 'save' : 'update'](query)
            this.posting = false
            this.$message.success(this.$t('global.submitOk'))
            this.close()
            this.$parent.onSubmit()
          } catch (error) {
            this.posting = false
          }
        }
      })
    }
  }
}
</script>
