<template>
  <div v-if="dialogFormVisible2">
    <el-dialog
      :title="titleTable"
      :visible.sync="dialogFormVisible"
      :width="'600px'"
      @close="close"
      :close-on-click-modal="false"
    >
      <el-form
        :model="deviceInfoForm"
        ref="form"
        :label-width="'110px'"
        label-position="right"
        size="large"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="gasId" :label="$t('add.排放物') + '：'">
              <span>{{
                typeList.find((ele) => ele.value == deviceInfoForm.gasId)
                  ? typeList.find((ele) => ele.value == deviceInfoForm.gasId)
                      .label
                  : ""
              }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              prop="factor"
              :label="$t('add.计算因子') + '：'"
            >
              <span>{{ deviceInfoForm.factor }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              prop="dataSource"
              :label="$t('add.数据来源') + '：'"
            >
              <span>{{ deviceInfoForm.dataSource }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              prop="factorUnit"
              :label="$t('add.因子单位') + '：'"
            >
              <span>{{ deviceInfoForm.factorUnit }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              prop="consumeMaterial"
              :label="$t('add.消耗物') + '：'"
            >
              <span>{{ deviceInfoForm.consumeMaterial }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              prop="factorLevel"
              :label="$t('add.因子等级') + '：'"
            >
              <span>{{ deviceInfoForm.factorLevel }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              prop="consumeMaterialUnit"
              :label="$t('add.消耗物单位') + '：'"
            >
              <span>{{ deviceInfoForm.consumeMaterialUnit }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              prop="factorDescription"
              :label="$t('add.因子说明') + '：'"
            >
              <span>{{ deviceInfoForm.factorDescription }}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="20">
            <el-form-item prop="status" :label="$t('add.状态') + '：'">
              <span>{{ deviceInfoForm.status ? "启用" : "停用" }}</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <div v-if="isModelInfo">
          <el-button
            type="primary"
            :loading="posting"
            size="small"
            @click="close()"
          >
            {{ $t("global.confirm") }}
          </el-button>
        </div>
        <div v-else>
          <el-button @click="close" size="small">{{
            $t("global.cancel")
          }}</el-button>
          <el-button
            type="primary"
            :loading="posting"
            size="small"
            @click="submitForm('form')"
          >
            {{ $t("global.confirm") }}
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from './dialog'
export default {
  directives: {},
  filters: {},
  loading: false,
  extends: base,
  props: {},
  data () {
    return {
    }
  },
  created () {

  },
  methods: {

  }
}
</script>
