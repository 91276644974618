<template>
  <div class="my-btns">
    <div>
      <el-radio-group v-model="factorType" size="small" @change="handleClick">
        <el-radio-button label="1" plain>{{ $t('global.排放因子') }}</el-radio-button>
        <el-radio-button label="2" plain>{{ $t('global.减排物') }}</el-radio-button>
      </el-radio-group>
    </div>
    <div>
      <el-button
        class="u-add"
        type="primary"
        @click="$parent.$parent.add()"
        :loading="posting"
        v-if="tool.checkButton('factor-add')"
      >
        {{ $t("add.新增自定义因子") }}
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      posting: false,
      isModeInfo: false,
      factorType: '1'
    }
  },
  methods: {
    handleClick () {
      this.$parent.$parent.init(this.factorType)
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/.el-tabs__nav-wrap::after {
  display: none;
}
/deep/.el-tabs__header {
  margin-left: 20px;
  margin-bottom: 0;
}
.my-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
