<script>
import api from '@/api'
import tool from '@/tool'
import base from '@/templates/list'
import MyDialog from './dialog'
import MyDialog2 from './dialog-info'
import Btns from './btns'
export default {
  directives: {},
  filters: {},
  components: {
    MyDialog,
    MyDialog2,
    Btns
  },
  extends: base,
  props: {},
  data () {
    return {
      showExport: false,
      handleTableColumnWidth: 225,
      tableAction: {
        add: { show: tool.checkButton('factor-add'), title: this.$t('global.add') },
        remove: { show: tool.checkButton('factor-remove'), title: this.$t('global.remove') },
        edit: { show: tool.checkButton('factor-edit'), title: this.$t('global.edit') },
        view: { show: tool.checkButton('factor-view'), title: this.$t('global.details') },
        valid: { show: tool.checkButton('factor-updateStatus-list') },
        updateStatus: { show: tool.checkButton('factor-selfTest-disable'), title: 'global.list.启用/停用自测值' }
      },
      showList: [
        {
          copName: 'el-input',
          placeholder: 'list.产品编号/名称',
          key: 'query',
          name: '',
          attrs: ['placeholder']
        },
        {
          copName: 'el-select',
          key: 'status',
          type: '',
          attrs: ['placeholder', 'options'],
          placeholder: 'list.状态',
          options: [
            { label: this.$t('global.启用'), value: '0' },
            { label: this.$t('global.停用'), value: '1' }
          ]
        },
        {
          copName: 'el-date-picker',
          placeholder: 'list.更新时间',
          key: 'updateTime',
          dates: [],
          attrs: ['copType', 'valueFormat', 'placeholder'],
          copType: 'date',
          valueFormat: 'yyyy-MM-dd'
        }
      ],
      tableTile: [
        {
          key: '0',
          columnLabel: 'global.序号',
          width: '80',
          type: 'index'
        },
        {
          key: 'gasName',
          columnLabel: 'list.排放物',
          prop: 'gasName',
          width: ''
        },
        {
          key: 'consumeMaterial',
          columnLabel: 'list.消耗物',
          prop: 'consumeMaterial',
          width: ''
        },
        {
          key: 'factorType',
          columnLabel: 'list.因子类型',
          prop: 'factorType',
          width: '',
          formatter: (row, column, cellValue, index) => {
            let temp = ''
            switch (cellValue) {
              case 1:
                temp = this.$t('global.排放因子')
                break
              case 2:
                temp = this.$t('global.减排物')
                break
            }
            return temp
          }
        },
        {
          key: 'factorUnit',
          columnLabel: 'list.因子单位',
          prop: 'factorUnit',
          width: ''
        },
        {
          key: 'consumeMaterialUnit',
          columnLabel: 'list.消耗物单位',
          prop: 'consumeMaterialUnit',
          width: ''
        },
        {
          key: 'emissionsName',
          columnLabel: 'list.关联排放源',
          prop: 'emissionsName',
          width: ''
        },
        {
          key: 'status',
          columnLabel: 'list.状态',
          prop: 'status',
          width: '',
          formatter: (row, column, cellValue, index) => {
            let temp = ''
            switch (cellValue) {
              case 0:
                temp = this.$t('global.启用')
                break
              case 1:
                temp = this.$t('global.停用')
                break
            }
            return temp
          }
        },
        {
          key: 'selfTestStatus',
          columnLabel: 'list.自测值状态',
          prop: 'selfTestStatus',
          width: '',
          formatter: (row, column, cellValue, index) => {
            let temp = ''
            switch (cellValue) {
              case 0:
                temp = this.$t('global.启用')
                break
              case 1:
                temp = this.$t('global.停用')
                break
            }
            return temp
          }
        },
        {
          key: 'factor',
          columnLabel: 'list.计算因子',
          prop: 'factor',
          width: ''
        },
        {
          key: 'updateTime',
          columnLabel: 'list.修改时间',
          prop: 'updateTime',
          width: '',
          formatter: (row, column, cellValue, index) => this.$moment(cellValue).format('YYYY-MM-DD HH:mm:ss')
        }
      ]
    }
  },
  created () {
  },
  methods: {
    init (factorType) {
      this.formInline.factorType = factorType || '1'
      this.toPage(1)
    },
    toPage (page) {
      this.formInline.page.currentPage = page
      this.onSubmit()
    },
    onSubmit () {
      this.loading = true
      const query = {
        page: this.formInline.page.currentPage,
        size: this.formInline.page.size,
        queryParam: {
          ...this.formInline
        }
      }
      api.factor.queryPage(query)
        .then((res) => {
          if (res.data && res.data.records) {
            this.tableData = res.data.records
            this.formInline.page.total = res.data.total
          }
          this.loading = false
        })
    },

    // 新增
    add () {
      this.$refs.myDialog.add()
    },

    // 删除
    async remove (id) {
      await this.$confirm(this.$t('global.removeTooltip'), {
        type: 'warning'
      })
      await api.factor.remove({ id: id })

      this.toPage(1)
    },

    // 编辑
    async edit (id) {
      const res = await api.factor.findById({ id })
      this.$refs.myDialog.edit({
        ...(res.data || {})
      })
    },

    // 详情
    async view (id) {
      const res = await api.factor.findById({ id })
      this.$refs.myDialog2.view({
        ...(res.data || {})
      })
    },

    // 启用
    async enable (id) {
      await this.$confirm(this.$t('global.enableTooltip'), { type: 'warning' })
      await api.factor.updateStatusById({ id: id, status: 1 })

      this.toPage(1)
    },

    // 停用
    async disable (id) {
      await this.$confirm(this.$t('global.disableTooltip'), { type: 'warning' })
      await api.factor.updateStatusById({ id: id, status: 0 })

      this.toPage(1)
    },
    // 更新自测值
    async updateStatus (row) {
      await this.$confirm(row.selfTestStatus === 0 ? this.$t('global.enableTooltip') : this.$t('global.disableTooltip'), { type: 'warning' })
      await api.factor.updateSelfTestStatusById({ id: row.id, selfTestStatus: row.selfTestStatus === 0 ? 1 : 0 })

      this.toPage(1)
    }
  }
}
</script>
